import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"

import kosmoImage from "../../assets/images/kosmoestetic.jpg"
import kosmoLogo from "../../assets/images/kosmo-logo.jpg"

const StyledRow = styled(Row)`
  border-top: 5px #e5007d solid;
  padding: 0;
  background-color: white;

  div {
    padding: 0;
  }

  p {
    font-size: 17px;
    color: rgb(122, 122, 122);
    line-height: 30px;
  }

  img#kosmo-logo {
    height: 6rem;
    margin: 0.5rem auto 0 auto;
  }

  img#kosmo-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (min-width: 576px) {
    margin-top: 6rem;
    margin-bottom: 5rem;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

function Kosmo({ kosmoData }) {
  const {
    opis: { opis: description },
    zdjecie,
  } = kosmoData[0]

  return (
    <Container>
      <StyledRow>
        <Col lg={7} className="pl-5 pr-5">
          <Row className="mt-4 mb-5">
            <img src={kosmoLogo} alt="kosmoestetic logo" id="kosmo-logo" />
          </Row>
          <p style={{ fontSize: "15px" }}>{description}</p>
          <Flex>
            <button
              className="button-primary mt-4 mb-4"
              onClick={() =>
                window.location.replace("http://www.kosmoestetic.pl/")
              }
            >
              Odwiedź KosmoEstetic
            </button>
          </Flex>
        </Col>
        <Col lg={5}>
          <Image
            id="kosmo-image"
            fluid={zdjecie.fluid}
            alt="Kosmo Estetic"
            className="image"
          />
        </Col>
      </StyledRow>
    </Container>
  )
}

export default Kosmo
