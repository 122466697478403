import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Home from "../components/Home"

export const query = graphql`
  {
    allContentfulSliderElement {
      nodes {
        id
        naglowek
        opis
        zdjecie {
          fluid(maxWidth: 2560) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    allContentfulKosmoestetic {
      nodes {
        opis {
          opis
        }
        zdjecie {
          fluid(maxWidth: 2560) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default function Homepage({ data }) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>fizjowsulkowski | Strona Główna</title>
        <meta name="theme-color" content={"white"} />
      </Helmet>
      <Home data={data} />
    </>
  )
}
